.bounce {
    animation: bounce bounce 1s ease-in 2s 6 alternate; /*infinite*/
    -webkit-animation: bounce 1s ease-in 2s 6 alternate; /*infinite*/
    -moz-animation: bounce bounce 1s ease-in 2s 6 alternate; /*infinite*/
    -o-animation: bounce bounce 1s ease-in 2s 6 alternate; /*infinite*/
}

@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
    }
}

@-moz-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -moz-transform: translateY(0);
    }

    40% {
        -moz-transform: translateY(-30px);
    }

    60% {
        -moz-transform: translateY(-15px);
    }
}

@-o-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -o-transform: translateY(0);
    }

    40% {
        -o-transform: translateY(-30px);
    }

    60% {
        -o-transform: translateY(-15px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.price-tag {
    background-color: #4285F4;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
}