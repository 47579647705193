@import url('https://cdn.syncfusion.com/ej2/material.css');

.mainContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    font-size: 64px;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
}

.resultContainer,
.historyItem {
    flex-direction: row;
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;
}

.historyContainer {
    flex-direction: column;
    display: flex;
    height: 200px;
    align-items: center;
    flex-grow: 5;
    justify-content: flex-start;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.inputContainer > .errorLabel {
    color: red;
    font-size: 12px;
}

.inputBox {
    height: 48px;
    width: auto;
    min-width: 250px;
    font-size: large;
    border-radius: 8px;
    border: 1px solid grey;
    padding-left: 8px;
}

.sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.box {
    /*background: linear-gradient(rgba(250, 0, 0, 0.5), transparent);*/
    background-image: url('./data/gradientsquare.png');
    background-color: #366976 /*cornflowerblue*/ /*this your primary color*/
}

.nav-item,
.navbar {
    z-index: 10000;
}

@media screen and (max-width: 800px) {
    .sidebar {
        z-index: 10000000;
    }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

/* color-picker style  */

#preview {
    background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
    display: inline-block;
    height: 80px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 600px;
    background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: none;
}
